import React from "react"
import styled from "styled-components"

import { NavBar } from "./Navbar"
import { Footer } from "./Footer"

const Layout = ({ children }: { children: any }) => {
  return (
    <Container>
      <NavBar />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

export default Layout

const Container = styled.div`
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
`

const Main = styled.main`
  margin: 0 auto;
  margin-top: ${props => props.theme.margins.m16};
  margin-bottom: ${props => props.theme.margins.m80};

  @media (min-width: ${props => props.theme.screens.md}) {
    margin-bottom: ${props => props.theme.margins.m48};
  }
`
