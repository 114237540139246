import React from "react"
import styled from "styled-components"
import { Logo } from "components/shared/Logo"
import { Link } from "gatsby"

import { Linkedin, Mail, Facebook } from "react-feather"

const LinkElement = ({ label, link }: { label: string; link: string }) => {
  return <LinkElementStyle to={link}>{label}</LinkElementStyle>
}

const SocialElement = ({
  render,
  href,
}: {
  render: () => JSX.Element
  href: string
}) => {
  return (
    <SocialElementStyle href={href} rel="noreferrer noopener" target="_blank">
      {render()}
    </SocialElementStyle>
  )
}

const Footer = () => {
  return (
    <Container>
      <ContentContainer>
        <Brand>
          <Logo width="100" link="/" />
          <Sub>
            Marque d'Imagine, Société par Actions Simplifiée, capital de 5000 €
            (Siren : 797 865 102 R.C.S. Meulun).
          </Sub>
        </Brand>
        <Links>
          <LinkElement label="CGU" link="/cgu"></LinkElement>
          <LinkElement label="Mentions Légales" link="/legal"></LinkElement>
          <LinkElement
            label="Données Personnelles"
            link="/privacy"
          ></LinkElement>
        </Links>
        <Social>
          <SocialElement
            render={() => <Linkedin strokeWidth={1.3} />}
            href="https://www.linkedin.com/company/myoneid/"
          />
          <SocialElement
            render={() => <Facebook strokeWidth={1.3} />}
            href="https://www.facebook.com/Oneid-102240891892189"
          />
          <SocialElement
            render={() => <Mail strokeWidth={1.3} />}
            href="mailto:contact@myoneid.fr?subject=Demande de renseignements"
          />
        </Social>
      </ContentContainer>
    </Container>
  )
}

export { Footer }

const Container = styled.footer`
  background-color: ${props => props.theme.colors.gray};
  width: 100%;
  display: flex;
  padding: ${props => props.theme.paddings.p5} 0;
  position: absolute;
  bottom: 0;
  width: 100%;
`

const ContentContainer = styled.div`
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  padding: 0 ${props => props.theme.paddings.p8};
  flex-direction: column;

  @media (min-width: ${props => props.theme.screens.md}) {
    flex-direction: row;
  }
`

const Brand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: ${props => props.theme.screens.md}) {
    align-items: flex-start;
    text-align: inherit;
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.margins.m4} 0;
  text-align: center;

  @media (min-width: ${props => props.theme.screens.md}) {
    flex-direction: row;
    margin: inherit;
    text-align: inherit;
  }
`

const LinkElementStyle = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.secondarydark};
  margin: ${props => props.theme.margins.m2} 0;

  @media (min-width: ${props => props.theme.screens.md}) {
    margin: 0 ${props => props.theme.margins.m2};
  }
`

const Social = styled.div`
  display: flex;
`

const Sub = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 300;
  color: ${props => props.theme.colors.secondarydark};
  max-width: 20rem;
  margin-top: ${props => props.theme.margins.m2};
`

const SocialElementStyle = styled.a`
  margin: 0 ${props => props.theme.margins.m2};
  text-decoration: none;
  color: ${props => props.theme.colors.secondarydark};
`
