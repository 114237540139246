import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button } from "components/shared/atoms/Button"
import { Logo } from "components/shared/Logo"
import { EXT_ROUTES } from "components/shared/routes"

const SideDrawer = (props: {
  isOpen: boolean
  closeMenu: () => void
  menuItems: Array<{ link: string; label: string }>
}) => {
  const { isOpen, closeMenu } = props

  const menuItems = [
    { link: "/#features", label: "Fonctionnalités" },
    { link: "/#references", label: "Références" },
    { link: "/#pricing", label: "Nos offres" },
    { link: "/404", label: "Contact" },
  ]

  return (
    <StyledMenu isOpen={isOpen}>
      <div className="logo">
        <Logo width="140" />
      </div>
      {menuItems.map(item => (
        <MenuItem onClick={closeMenu} to={item.link} key={item.link}>
          {item.label}
        </MenuItem>
      ))}
      <span className="divider">
        <Button link={EXT_ROUTES.LOGIN}>Se connecter</Button>
      </span>
    </StyledMenu>
  )
}

export { SideDrawer }

export const StyledMenu = styled.nav<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.gray};
  height: 100vh;
  padding: ${props => props.theme.paddings.p4};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.1s ease-in-out;

  a {
    padding: ${props => props.theme.paddings.p2} 0;
    color: ${props => props.theme.colors.secondarydark};
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }

  .divider {
    padding-top: ${props => props.theme.paddings.p3};
  }

  .logo {
    margin-bottom: ${props => props.theme.margins.m8};
  }
`

const MenuItem = styled(Link)`
  margin: ${props => props.theme.margins.m3} 0;
  text-decoration: none;
  color: ${props => props.theme.colors.secondarydark};
  display: inline-block;
  white-space: nowrap;
  transition: all 200ms ease-in;
`
