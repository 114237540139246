import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Logo } from "components/shared/Logo"
import { Menu, X } from "react-feather"
import { Button } from "components/shared/atoms/Button"
import { SideDrawer } from "./SideDrawer"
import { Link } from "gatsby"
import { EXT_ROUTES } from "components/shared/routes"

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const menuItems = [
    { link: "/#features", label: "Fonctionnalités" },
    { link: "/#secure", label: "Sécurité" },
    { link: "/#references", label: "Références" },
    { link: "/#pricing", label: "Nos offres" },
  ]

  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 10)
  }

  const openMenu = () => {
    setIsMenuOpened(true)
    // Prevent scrolling
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = "fixed"
  }

  const closeMenu = () => {
    // Reverse scrolling prevention
    const scrollY = document.body.style.top
    document.body.style.top = ""
    document.body.style.position = ""
    window.scrollTo(0, parseInt(scrollY || "0") * -1)
    setIsMenuOpened(false)
  }

  return (
    <header>
      <Container isScrolled={isScrolled}>
        <Navigation>
          <Logo width="120" link="/" />
          <StyledBurger>
            {isMenuOpened ? (
              <X onClick={closeMenu} />
            ) : (
              <Menu onClick={openMenu} />
            )}
          </StyledBurger>
          <LinksContainer>
            <span className="divider">
              {menuItems.map(item => (
                <StyledLink to={item.link} key={item.link}>
                  {item.label}
                </StyledLink>
              ))}
            </span>
            <Button link={EXT_ROUTES.LOGIN}>Se connecter</Button>
          </LinksContainer>
        </Navigation>
        <SideDrawer
          isOpen={isMenuOpened}
          closeMenu={closeMenu}
          menuItems={menuItems}
        />
      </Container>
    </header>
  )
}

export { NavBar }

const Navigation = styled.nav`
  max-width: 1140px;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${props => props.theme.margins.m2} auto;
  align-self: center;
  height: 3rem;
  padding: 0 ${props => props.theme.paddings.p8};
  position: relative;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.secondarydark};
  display: inline-block;
  white-space: nowrap;
  margin: 0 ${props => props.theme.margins.m3};
  transition: all 200ms ease-in;
  position: relative;
  padding: ${props => props.theme.paddings.p8} 0;
  font-size: 1.5rem;
  z-index: 6;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: ${props => props.theme.colors.palette.blueDark};
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: ${props => props.theme.colors.palette.blueDark};
    ::after {
      width: 100%;
    }
  }

  @media (min-width: ${props => props.theme.screens.md}) {
    padding: inherit;
    font-size: inherit;
    z-index: inherit;
  }
`

const LinksContainer = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.screens.md}) {
    display: block;
  }
  .divider {
    margin-right: ${props => props.theme.margins.m3};
  }
`

const Container = styled.div<{ isScrolled: boolean }>`
  position: fixed;
  box-shadow: ${props =>
    props.isScrolled ? "0px 2px 4px 3px rgba(0, 0, 0, 0.2)" : ""};

  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  height: auto;
  transition: box-shadow 0.3s linear, background-color 0.3s ease,
    height 0.3s ease;
  background-color: ${props => (props.isScrolled ? "#fff" : "rgba(0,0,0,0)")};
`

const StyledBurger = styled.button`
  position: absolute;
  top: 20%;
  right: 1.5rem;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.screens.md}) {
    display: none;
  }

  &:focus {
    outline: none;
  }
`
